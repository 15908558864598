
























import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class ShowsListItem extends Vue {
  @Prop({ type: String })
  to!: string

  @Prop()
  image!: any

  @Prop({ type: String, required: true })
  title!: string

  @Prop({ type: String, required: true })
  subtitle!: string

  @Prop(String)
  bookingLink!: string

  @Prop(Boolean)
  soldOut!: boolean

  @Prop(Boolean)
  cancelled!: boolean

  get link () {
    if (this.$route.path === this.to)
      return null;

    return this.to;
  }
}
